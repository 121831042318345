var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.item == 4)?_c('base-card',[_c('v-card-text',[_c('v-card-text',[_c('div',{staticClass:"mb-5"},[_c('span',{staticClass:"text-h6"},[_vm._v("Payment Type")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-btn',{attrs:{"small":"","color":"primary","dark":""},on:{"click":function($event){(_vm.title = 'Add'), _vm.openDialog()}}},[_vm._v(" Add Payment Type ")])],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{staticClass:"mt-n5",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.paymentList,"search":_vm.search},on:{"click:row":_vm.handleClick}})],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('base-card',[_c('v-card-text',{staticClass:"mt-3"},[_c('div',{staticClass:"mb-5"},[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.title)+" Payment Type")])]),_c('v-select',{attrs:{"items":_vm.availablePaymentList,"prepend-icon":"mdi-cash-multiple","item-text":"name","item-value":"payment_type_id","label":"Select Payment Type","error-messages":_vm.selectPaymentTypeErrors,"required":""},on:{"change":function($event){_vm.updateName(_vm.selectPaymentType), (_vm.imageAllow = 0)},"input":function($event){return _vm.$v.selectPaymentType.$touch()},"blur":function($event){return _vm.$v.selectPaymentType.$touch()}},model:{value:(_vm.selectPaymentType),callback:function ($$v) {_vm.selectPaymentType=$$v},expression:"selectPaymentType"}}),(
            _vm.selectPaymentType != '1' &&
            _vm.selectPaymentType != '2' &&
            _vm.selectPaymentType != ''
          )?_c('v-checkbox',{staticClass:"mt-n1",attrs:{"false-value":"0","true-value":"1","label":"Set Image"},on:{"change":function($event){(_vm.fileRecords = []), (_vm.fileRecordsForUpload = [])}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_vm._v(" The image will be display on second screen when payment select. (Recommend size: 500 x 500 px) ")])]},proxy:true}],null,false,2032415670),model:{value:(_vm.imageAllow),callback:function ($$v) {_vm.imageAllow=$$v},expression:"imageAllow"}}):_vm._e(),(_vm.imageAllow == 1)?_c('div',{staticClass:"mt-n6"},[_c('v-row',[_c('v-col',[_c('VueFileAgent',{ref:"vueFileAgent",attrs:{"theme":'list',"multiple":false,"deletable":true,"meta":true,"accept":'image/*',"maxSize":'700KB',"maxFiles":1,"helpText":'Choose files or drag & drop here',"errorText":{
                  type: 'Invalid file type. Only images Allowed',
                  size: 'Files should not exceed 700KB in size',
                }},on:{"select":function($event){return _vm.filesSelected($event)},"beforedelete":function($event){return _vm.onBeforeDelete($event)},"delete":function($event){return _vm.fileDeleted($event)}},model:{value:(_vm.fileRecords),callback:function ($$v) {_vm.fileRecords=$$v},expression:"fileRecords"}})],1)],1)],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[(_vm.title == 'Edit')?_c('v-btn',{attrs:{"text":"","icon":"","color":"red lighten-2"},on:{"click":function($event){_vm.dialogDelete = true}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.checkLoading,"text":""},on:{"click":function($event){_vm.dialogAction(), (_vm.checkLoading = true)}}},[_vm._v(" save ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-subtitle-1"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deletePayment}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }