<template>
  <div>
    <base-card v-if="item == 2">
      <v-card-text>
        <v-card-text>
          <div class="mb-5"><span class="text-h6">Branch</span></div>
          <v-row>
            <v-col cols="12" lg="6"
              ><v-btn
                v-if="addButton"
                small
                color="primary"
                @click="(title = 'Add'), openDialog(), croppa.remove()"
                dark
              >
                Add Branch
              </v-btn></v-col
            >
            <v-spacer></v-spacer>
            <v-col cols="12" lg="6"
              ><v-text-field
                class="mt-n5"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field
            ></v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="branchList"
            :search="search"
          
            @click:row="handleClick"
          >
            <template v-slot:item.address="{ item }">
              <div class="text-truncate" style="max-width: 160px">
                {{ item.address }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card-text>
    </base-card>
    <v-dialog v-model="dialog" width="600">
      <base-card>
        <v-card-text class="mt-3">
          <div class="mb-5">
            <span class="text-h6">{{ title }} branch</span>
          </div>
          <v-row>
            <v-spacer></v-spacer>
            <croppa
              v-model="croppa"
              :prevent-white-space="true"
              placeholder="Choose or drop branch logo here"
              :placeholder-font-size="12"
              :file-size-limit="1048576"
              accept=".jpeg,.jpg,.png"
              @file-size-exceed="onFileSizeExceed"
              @new-image-drawn="onFileChoose"
              @image-remove="onImageRemove"
            >
              >
              <img crossOrigin="anonymous" :src="imageName" slot="initial" />
            </croppa>
            <v-spacer></v-spacer>
          </v-row>
          <v-text-field
            prepend-icon="mdi-license"
            label="Company Register No"
            v-model="bCompanyRegisterNo"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-store"
            label="Branch Name"
            v-model="bName"
            :error-messages="bNameErrors"
            required
            @input="$v.bName.$touch()"
            @blur="$v.bName.$touch()"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-email"
            label="Branch Email"
            v-model="bEmail"
            :error-messages="bEmailErrors"
            @input="$v.bEmail.$touch()"
            @blur="$v.bEmail.$touch()"
          ></v-text-field>
          <v-text-field
            prepend-icon="mdi-phone"
            label="Phone"
            type="number"
            v-model="bPhone"
          ></v-text-field>
          <!-- <v-text-field
            prepend-icon="mdi-phone"
            label="Phone"
            type="number"
            v-model="bPhone"
            placeholder="016xxxxxxxx"
            :error-messages="bPhoneErrors"
            required
            @input="$v.bPhone.$touch()"
            @blur="$v.bPhone.$touch()"
          ></v-text-field> -->
          <v-text-field
            prepend-icon="mdi-map-marker"
            label="Address"
            v-model="bAddress"
          ></v-text-field>
          <v-row>
            <v-col cols="6">
              <v-menu
                ref="smenu"
                v-model="stmenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="stime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="stime"
                    label="Start Working Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    :error-messages="stimeErrors"
                    required
                    @input="$v.stime.$touch()"
                    @blur="$v.stime.$touch()"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="stmenu"
                  v-model="stime"
                  full-width
                  @click:minute="$refs.smenu.save(stime)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="emenu"
                v-model="etmenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="etime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="etime"
                    label="End Working Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    :error-messages="etimeErrors"
                    required
                    @input="$v.etime.$touch()"
                    @blur="$v.etime.$touch()"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="etmenu"
                  v-model="etime"
                  :min="stime"
                  full-width
                  @click:minute="$refs.emenu.save(etime)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <!-- <v-btn
            v-if="title == 'Edit'"
            text
            icon
            color="red lighten-2"
            @click="dialogDelete = true"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn> -->
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogAction" text> save </v-btn>
        </v-card-actions>
      </base-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="text-subtitle-1"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteBranch">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { BASEURL, BASESYNCURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
  not,
} from "vuelidate/lib/validators";

export default {
  props: {
    item: {
      type: Number,
      default: "",
    },
  },
  mixins: [validationMixin],

  validations: {
    bName: { required },
    stime: { required },
    etime: { required, sameAs: not(sameAs("stime")) },
    bEmail: { email },
    // bPhone: {
    //   minLength: minLength(10),
    //   maxLength: maxLength(11),
    // },
  },


  data: () => ({
    domain: BASEURL,
    syncDomain: BASESYNCURL,
    snackbar: false,
    text: "",
    snackbarColor: "",
    timeout: 1900,
    dialogDelete: "",
    title: "",
    branchList: [],
    bName: "",
    bEmail: "",
    bPhone: "",
    bAddress: "",
    bCompanyRegisterNo: "",
    stime: null,
    etime: null,
    stmenu: false,
    etmenu: false,
    croppa: {},
    imageName: "",
    logoName: "",
    dataUrl: "",
    selectBranch: "",
    dialog: false,
    companyID: "",
    search: "",
    branch_amount: "",
    addButton: false,
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: false,
      },
      { text: "Phone", value: "phone", sortable: false },
      {
        text: "Address",
        value: "address",
        sortable: false,
      },
      {
        text: "Number of POS",
        value: "numberDevice",
        sortable: false,
      },
      {
        text: "Number of Staff",
        value: "numberStaff",
        sortable: false,
      },
    ],
  }),

  computed: {
    bNameErrors() {
      const errors = [];
      if (!this.$v.bName.$dirty) return errors;
      !this.$v.bName.required && errors.push("Branch name is required");
      return errors;
    },
    bEmailErrors() {
      const errors = [];
      if (!this.$v.bEmail.$dirty) return errors;
      !this.$v.bEmail.email && errors.push("Please enter an valid email");
      return errors;
    },

    stimeErrors() {
      const errors = [];
      if (!this.$v.stime.$dirty) return errors;
      !this.$v.stime.required && errors.push("Start time is required");
      return errors;
    },

    etimeErrors() {
      const errors = [];
      if (!this.$v.etime.$dirty) return errors;
      !this.$v.etime.required && errors.push("End time is required");
      !this.$v.etime.sameAs &&
        errors.push("End time cannot be same as start time");
      return errors;
    },

    // bPhoneErrors() {
    //   const errors = [];
    //   if (!this.$v.bPhone.$dirty) return errors;
    //   !this.$v.bPhone.minLength && errors.push("Phone must at least 10 number");
    //   !this.$v.bPhone.maxLength &&
    //     errors.push("Phone maximum consist of 11 number");
    //   return errors;
    // },
  },

  mounted() {
    this.$root.$on("component1", () => {
      this.getCompanyBranch();
    });
  },

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getCompanyBranch();
    this.getAllSubscription();
  },
  methods: {
    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.branch;
          } else {
            console.log("no list get");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllSubscription() {
      const params = new URLSearchParams();
      params.append("read", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/subscription/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branch_amount = response.data.subscription[0].branch_amount;
            if (this.branchList.length < this.branch_amount) {
              this.addButton = true;
            } else {
              this.addButton = false;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    dialogAction() {
      
      if (this.title == "Add") {
        this.$v.$touch();
        if (!this.$v.$invalid) {
            const params = new URLSearchParams();
            params.append("addBranch", "done");
            params.append("company_id", this.companyID);
            params.append("name", this.bName);
            params.append("stime", this.stime);
            params.append("etime", this.etime);
            params.append("email", this.bEmail);
            params.append("image", this.dataUrl);
            params.append("phone", this.bPhone);
            params.append("address", this.bAddress);
            params.append("register_no", this.bCompanyRegisterNo);
            axios({
              method: "post",
              url: this.domain + "/branch/index.php",
              data: params,
            })
              .then((response) => {
                console.log(response);
                if (response.data.status == "1") {
                  this.dialog = false;
                  this.text = "Successfully add";
                  this.snackbar = true;
                  this.snackbarColor = "success";
                  this.getCompanyBranch();
                  this.sendNotication(response.data.branch_id);
                  this.$root.$emit('component2') //refresh pos card
               
                } else {
                  this.text = "Please try again later";
                  this.snackbar = true;
                  this.snackbarColor = "error";
                }
              })
              .catch((error) => {
                console.log(error);
              });
        }
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
            const params = new URLSearchParams();
            params.append("editBranch", "done");
            params.append("company_id", this.companyID);
            params.append("branch_id", this.selectBranch);
            params.append("name", this.bName);
            params.append("stime", this.stime);
            params.append("etime", this.etime);
            params.append("email", this.bEmail);
            params.append("oldImage", this.logoName);
            params.append("image", this.dataUrl);
            params.append("phone", this.bPhone);
            params.append("address", this.bAddress);
            params.append("register_no", this.bCompanyRegisterNo);
            axios({
              method: "post",
              url: this.domain + "/branch/index.php",
              data: params,
            })
              .then((response) => {
                console.log(response);
                if (response.data.status == "1") {
                  this.dialog = false;
                  this.text = "Successfully edit";
                  this.snackbar = true;
                  this.snackbarColor = "success";
                  this.getCompanyBranch();
                  this.sendNotication(this.selectBranch);
                  this.$root.$emit('component2')

                } else {
                  this.text = "Please try again later";
                  this.snackbar = true;
                  this.snackbarColor = "error";
                }
              })
              .catch((error) => {
                console.log(error);
              });
        }
      }
    },

    openDialog() {
      if (this.title == "Add") {
        this.$v.$reset();
        this.bName = "";
        this.bPhone = "";
        this.dataUrl = "";
        this.bEmail = "";
        this.stime = "";
        this.etime = "";
        this.bAddress = "";
        this.selectBranch = "";
        this.dialog = true;
      } else {
        this.$v.$reset();
        this.dialog = true;
      }
    },

    handleClick(item) {
      const working_time = JSON.parse(item.working_time);
      this.bName = item.name;
      this.bEmail = item.email;
      this.stime = working_time[0];
      this.etime = working_time[1];
      this.bPhone = item.phone;
      this.bAddress = item.address;
      this.bCompanyRegisterNo = item.register_no;
      this.selectBranch = item.branch_id;

      this.$nextTick(() => {
        if (item.logo != "") {
          this.imageName =
            this.domain + "logo/" + this.companyID + "/" + item.logo;
        } else {
          this.imageName = "";
        }
      });

      this.logoName = item.logo;
      this.dataUrl = "";
      this.title = "Edit";
      this.openDialog();
    },

    onFileChoose(e) {
      this.dataUrl = this.croppa.generateDataUrl();
    },

    onFileSizeExceed(file) {
      this.text = "File size exceeds. Please choose a file smaller than 1mb.";
      this.snackbar = true;
      this.snackbarColor = "error";
      this.croppa.refresh();
    },
    onImageRemove(e) {
      this.dataUrl = "";
      this.logoName = "";
    },

    deleteBranch() {
      const params = new URLSearchParams();
      params.append("delete", "done");
      params.append("branch_id", this.selectBranch);
      params.append("company_id", this.companyID);
      params.append("logo_name", this.logoName);
      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.dialog = false;
            this.text = "Successfully delete";
            this.snackbar = true;
            this.snackbarColor = "success";
            this.dialogDelete = false;
            this.getCompanyBranch();
            this.sendNotication(this.selectBranch);
            this.$root.$emit('component2') 
          } else {
            this.text = "Please try again later";
            this.snackbar = true;
            this.snackbarColor = "error";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sendNotication(branch_id) {
      const params = new URLSearchParams();
      params.append("send_notification", "done");
      params.append("branch_id", branch_id);
      params.append("type", "1");

      axios({
        method: "post",
        url: this.syncDomain + "/sync/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("Send Notification successfully");
          } else {
            console.log("Send Notification fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>